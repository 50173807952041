import type {
  TooltipContentProps,
  TooltipProps,
} from "@radix-ui/react-tooltip";
import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { Loader2 } from "lucide-react";

import { cn } from ".";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  tooltipText?: string;
  tooltipProps?: TooltipProps;
  tooltipContentProps?: TooltipContentProps;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      isLoading = false,
      loadingText,
      asChild = false,
      tooltipText,
      tooltipProps,
      tooltipContentProps,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    if (asChild) {
      return (
        <Comp
          className={cn(buttonVariants({ variant, size, className }))}
          ref={ref}
          {...props}
        />
      );
    }

    if (tooltipText) {
      return (
        <TooltipProvider>
          <Tooltip {...tooltipProps}>
            <TooltipTrigger asChild>
              <button
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...props}
                disabled={
                  isLoading && props.disabled === undefined
                    ? true
                    : props.disabled
                }
              >
                <>
                  {isLoading && (
                    <Loader2
                      className={cn("mr-2", "h-4", "w-4", "animate-spin")}
                    />
                  )}

                  {isLoading ? loadingText ?? props.children : props.children}
                </>
              </button>
            </TooltipTrigger>
            <TooltipContent {...tooltipContentProps}>
              {tooltipText}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return (
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        disabled={isLoading}
      >
        <>
          {isLoading && (
            <Loader2 className={cn("mr-2", "h-4", "w-4", "animate-spin")} />
          )}

          {isLoading ? loadingText ?? props.children : props.children}
        </>
      </button>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
