import { useToast } from "@ezra/ui/use-toast";

interface ResultsToastOptions {
  successMessage?: string;
}
export const useResultsToast = () => {
  const { toast } = useToast();
  return async <U,>(
    func: () => Promise<U> | undefined,
    options?: ResultsToastOptions,
  ) => {
    // return async (...args: T): Promise<U | undefined> => {
    try {
      const returnValue = await func();
      if (options?.successMessage) {
        toast({
          title: "Success",
          description: options.successMessage,
        });
      }
      return returnValue;
    } catch (error) {
      if (error instanceof Error) {
        toast({
          title: "Error",
          description: error.message,
          variant: "destructive",
        });
      }
      return null;
    }
    // };
  };
};
