import { object, string } from "zod";

export const emailPasswordSignInSchema = object({
  email: string({ required_error: "Email is required" })
    .min(1, "Email is required")
    .email("Invalid email"),
  password: string({ required_error: "Password is required" })
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
});

export const registerSchema = object({
  firstName: string({ required_error: "First name is required" }).min(
    1,
    "First name is required",
  ),
  lastName: string({ required_error: "Last name is required" }).min(
    1,
    "Last name is required",
  ),
  email: string({ required_error: "Email is required" })
    .min(1, "Email is required")
    .email("Invalid email"),
  password: string({ required_error: "Password is required" })
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
});

export const requestResetSchema = object({
  email: string({ required_error: "Email is required" })
    .min(1, "Email is required")
    .email("Invalid email"),
});

export const resetPasswordSchema = object({
  token: string({ required_error: "Token is required" }).min(
    1,
    "Token is required",
  ),
  newPassword: string({ required_error: "Password is required" })
    .min(1, "Password is required")
    .min(8, "Password must be at least 8 characters long"),
});

export const changePasswordSchema = object({
  currentPassword: string({
    required_error: "Current password is required",
  }).min(1, "Current password is required"),
  newPassword: string({ required_error: "New password is required" })
    .min(1, "New password is required")
    .min(8, "New password must be at least 8 characters long"),
});
