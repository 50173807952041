import type { FieldValues, Path, UseFormReturn } from "react-hook-form";

import type { InputProps } from "./input";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./form";
import { Input } from "./input";

interface FormInputProps<
  TFieldValues extends FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
> {
  form: UseFormReturn<TFieldValues, TContext, TTransformedValues>;
  name: Path<TFieldValues>;
  label: string;
  inputProps?: InputProps;
  onChange?: (value: string) => void;
  outputType?: "string" | "float" | "int";
  required?: boolean;
}

export const FormInput = <
  TFieldValues extends FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  form,
  name,
  label,
  inputProps,
  onChange,
  outputType = "string",
  required,
}: FormInputProps<TFieldValues, TContext, TTransformedValues>) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>{label + (required ? " *" : "")}</FormLabel>
            <FormControl>
              <Input
                {...inputProps}
                {...field}
                onChange={(e) => {
                  if (inputProps?.type === "number" && outputType === "float") {
                    field.onChange(parseFloat(e.target.value));
                  } else if (
                    inputProps?.type === "number" &&
                    outputType === "int"
                  ) {
                    field.onChange(parseInt(e.target.value));
                  } else {
                    field.onChange(e.target.value);
                  }
                  onChange && onChange(e.target.value);
                }}
                value={"" + field.value}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};
