"use client";

import type { z } from "zod";
import React, { useState } from "react";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { createResetPasswordPageHref } from "@ezra/routes";
import { Button } from "@ezra/ui/button";
import { CardContent } from "@ezra/ui/card";
import { Form } from "@ezra/ui/form";
import { FormInput } from "@ezra/ui/FormInput";
import { emailPasswordSignInSchema } from "@ezra/validators/authSchemas";

import { useResultsToast } from "../../_hooks/useResultsToast";
import { login } from "../../actions/login";

export const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get("callbackUrl");
  const withResultsToast = useResultsToast();
  const [error, setError] = useState<string | null>(null);
  const form = useForm<z.infer<typeof emailPasswordSignInSchema>>({
    resolver: zodResolver(emailPasswordSignInSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: z.infer<typeof emailPasswordSignInSchema>) => {
    setLoading(true);
    setError(null);
    await withResultsToast(async () => {
      const result = await login(
        {
          email: data.email,
          password: data.password,
        },
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        callbackUrl ?? "/",
      );
      if (result?.error) {
        setError(result.error);
      }
    });
    setLoading(false);
  };

  return (
    <CardContent>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="mb-4 space-y-4">
          <FormInput
            label="Email"
            name="email"
            form={form}
            inputProps={{ type: "email" }}
          />
          <FormInput
            label="Password"
            name="password"
            form={form}
            inputProps={{ type: "password" }}
          />
          {error && <p className="text-red-500">{error}</p>}
          <Button type="submit" className="w-full" isLoading={loading}>
            Login
          </Button>
        </form>
      </Form>
      <Link
        href={createResetPasswordPageHref()}
        className="text-sm text-muted-foreground"
      >
        Forgot your password?
      </Link>
    </CardContent>
  );
};
